<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>通知中心</a-breadcrumb-item>
        </a-breadcrumb>

        <div class="main-box notification_box">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-button icon="sound" type="dashed" @click="handleAll()">全部标记为已读</a-button>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item>
                        <a-radio-group :value="searchParams.search.seen">
                            <a-radio-button @click="handleTypeButChange(0)" :value="0">
                                未读
                            </a-radio-button>
                            <a-radio-button @click="handleTypeButChange(1)" :value="1">
                                已读
                            </a-radio-button>
                        </a-radio-group>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div @click.stop class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item @click="handleUnread(item)" slot="renderItem" slot-scope="item, index">
                        <a-list-item-meta
                                :description="item.notification_title"
                            >
                                <a slot="title" href="javascript:;">{{ item.notification_account.notification_name }} <a-badge v-if="item.notification_seen==0" status="error" /></a>
                                <a-avatar
                                slot="avatar"
                                :src="item.notification_account.notification_avatar"
                                />
                        </a-list-item-meta>
                        <span>{{item.notification_time}}</span>
                    </a-list-item>
                </a-list>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'notification',
        data() {
            return {
                list: [],
                loading:false,
                isMessList:false,
            }
        },
        mixins:[tableMixins],
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('notificationIndexAction', this.searchParams)
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async handleUnread(item){
                if(item.notification_seen == 0){
                    let res = await this.$store.dispatch('notificationSeenAction', {notification_id:item.notification_id})
                    item.notification_seen = 1
                    this.$message.success('操作成功!')
                }
            },
            async handleAll(){
                this.isMessList = false
                let res = await this.$store.dispatch('notificationSeenAllAction', {})
                this.isMessList = true
                this.getList()
                this.$message.success('操作成功!')
            },
            handleTypeButChange(e){
                if(this.searchParams.search.seen === e){
                    this.searchParams.search.seen = undefined
                }else{
                    this.searchParams.search.seen = e
                }
                this.getList()
            },
        },
    }
</script>

<style lang="scss" scoped>
    .notification_box{
        width: 70%;
    }
</style>